<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { onUnmounted } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

type Props = {
    isLoggedIn?: boolean
    title?: string
    subTitle?: string
    isBigBanner?: boolean,
    enteredEmail: string,
    emailAuthorizationError: string,
    tooSoonSinceLastEmail: boolean,
    isSubmitted: boolean,
    emailExist: boolean
}

withDefaults(defineProps<Props>(), {
    isLoggedIn: false,
    title: 'Unlock your Hostel Account to Freedom!',
    subTitle: 'Sign up with Hostelz.com and get access to exclusive hostel content and much more.',
    isBigBanner: true
})

const emit = defineEmits(['submitSignUpForm', 'updateEnteredEmail', 'updateAgreeWithPolicyAndConditions', 'onUnmounted', 'backToForm'])

onUnmounted(() => {
    emit('onUnmounted')
})
</script>

<template>
    <section v-if="!isLoggedIn" class="banner-sign-up-form-wrap">
        <div class="dark-overlay" :class="{'dark-overlay--small-banner': !isBigBanner}">
            <picture>
                <source :srcset="route('images', 'signup-hostels.webp')" type="image/webp">

                <img
                    class="bg-image"
                    :v-lazy="route('images', 'signup-hostels.jpg')"
                    :alt="title"
                    :title="title"
                >
            </picture>

            <div
                class="container col-12 position-relative overlay-content banner-sign-up-form-wrap-body"
                :class="{'col-md-8': isBigBanner}"
            >
                <div
                    class="align-items-center justify-content-center text-center text-white banner-sign-up-form-wrap-content"
                >
                    <p v-if="isBigBanner" class="text-white h2">
                        {{ title }}
                    </p>

                    <p v-if="isBigBanner">
                        {{ subTitle }}
                    </p>

                    <div v-if="tooSoonSinceLastEmail" class="row mb-3">
                        <div class="mx-auto" :class="{'col-md-6': isBigBanner, 'col-11': !isBigBanner}">
                            <div

                                class="alert alert-warning w-100 text-center hostels-list-item-partial-availability-btn mb-0"
                            >
                                <FontAwesomeIcon :icon="faExclamationCircle" class="fa-fw mr-1" />

                                We've sent a confirmation email to this address. Please verify your account to continue.
                            </div>
                        </div>
                    </div>

                    <div v-if="emailExist" class="row mb-3">
                        <div class="mx-auto" :class="{'col-md-6': isBigBanner, 'col-11': !isBigBanner}">
                            <div class="email-exist-block">
                                <h1 class="h4 mb-0">
                                    Email already registered
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div v-if="emailAuthorizationError" class="row mb-3">
                        <div class="mx-auto" :class="{'col-md-6': isBigBanner, 'col-11': !isBigBanner}">
                            <div class="alert alert-danger mb-0">
                                {{ emailAuthorizationError }}
                                <!-- <TheIcon
                                    icon-id="denied-icon-animated"
                                    width="50"
                                    height="50"
                                /> -->
                                <svg
                                    id="denied-icon-animated"
                                    class="denied-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                >
                                    <circle
                                        class="denied-circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                    />
                                    <path class="denied-x" fill="none" d="M16 16, 36 36M36 16, 16 36" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div v-if="isSubmitted" class="row mb-3">
                        <div class="mx-auto" :class="{'col-md-6': isBigBanner, 'col-11': !isBigBanner}">
                            <div class="alert alert-success mb-0">
                                <p class="mb-3 text-break">
                                    We've sent an activation link to "{{ enteredEmail }}"
                                </p>
                                <!-- <TheIcon
                                    icon-id="check-icon-animated"
                                    width="50"
                                    height="50"
                                /> -->

                                <svg class="checkicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle
                                        class="checkicon__circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                    />
                                    <path class="checkicon__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <slot />

                    <p
                        v-if="isSubmitted || emailAuthorizationError || emailExist || tooSoonSinceLastEmail"
                        class="text-uppercase cursor-pointer d-inline-block p-2"
                        :class="{'mb-0': !isBigBanner}"
                        @click="emit('backToForm')"
                    >
                        &#60; Back
                    </p>

                    <div v-if="isBigBanner" class="mb-4">
                        <span>Do you already have an account?</span>

                        <Link
                            class="ml-1 font-weight-bold text-white"
                            :href="route('loginShow')"
                        >
                            Login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.email-exist-block {
    background-color: #efefef;
    color: var(--main-text);
    border-radius: 16px;
    padding: 16px;
}

.banner-sign-up-form-wrap {
    &-body {
        padding: 24px 15px;
    }

    &-content {
        padding: 96px 0;
    }
}

.dark-overlay--small-banner {
    display: flex;
    align-items: center;

    @media (min-width: 992px) {
        display: block;
    }
}
</style>