<script setup lang="ts">

import {
    clearAuthorizationFormData,
    emailAuthorizationError,
    emailExist,
    isSubmitted,
    isUserLogged, setAuthorizationStatus, signUpEndpoint,
    tooSoonSinceLastEmail
} from '@/Composables/authorization'
import BannerSignupFormWrap from '@/Components/banner-signup-form/BannerSignupFormWrap.vue'
import BannerSignupForm from '@/Components/banner-signup-form/BannerSignupForm.vue'
import { ref } from 'vue'

defineProps<{
    isBigBanner?: boolean
}>()

const bannerSignupForm = ref<InstanceType<typeof BannerSignupForm> | null>(null)

const enteredEmail = ref('')

const showAgreement = ref(false)

const updateEnteredEmail = (event: Event): void => {
    const target = event.target as HTMLInputElement

    enteredEmail.value = target.value

    if (bannerSignupForm.value?.emailInput && bannerSignupForm.value?.emailInput.validity.valid) {
        showAgreement.value = true
    } else {
        showAgreement.value = false
    }
}

const agreeWithPolicyAndConditions = ref(false)

const updateAgreeWithPolicyAndConditions = (event: Event): void => {
    const target = event.target as HTMLInputElement

    agreeWithPolicyAndConditions.value = target.checked
}

const submitSignUpForm = (): void => {
    const request = {
        url: '/signup',
        email: enteredEmail.value
    }

    signUpEndpoint(request, (data) => {
        setAuthorizationStatus(data)
    })
}

const backToForm = (): void => {
    clearAuthorizationFormData()

    enteredEmail.value = ''

    agreeWithPolicyAndConditions.value = false

    showAgreement.value = false
}

</script>

<template>
    <BannerSignupFormWrap
        :is-big-banner="isBigBanner"
        :is-logged-in="isUserLogged"
        :entered-email="enteredEmail"
        :is-submitted="isSubmitted"
        :email-authorization-error="emailAuthorizationError"
        :email-exist="emailExist"
        :too-soon-since-last-email="tooSoonSinceLastEmail"
        @on-unmounted="clearAuthorizationFormData"
        @back-to-form="backToForm"
    >
        <div v-if="!emailExist && !isSubmitted && !emailAuthorizationError && !tooSoonSinceLastEmail" class="mb-4">
            <BannerSignupForm
                ref="bannerSignupForm"
                :is-big-banner="isBigBanner"
                :entered-email="enteredEmail"
                :agree-with-policy-and-conditions="agreeWithPolicyAndConditions"
                :show-agreement="showAgreement"
                @submit-sign-up-form="submitSignUpForm"
                @update-entered-email="updateEnteredEmail"
                @update-agree-with-policy-and-conditions="updateAgreeWithPolicyAndConditions"
            />
        </div>
    </BannerSignupFormWrap>
</template>

<style scoped lang="scss">

</style>