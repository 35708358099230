<script setup lang="ts">
import { ref } from 'vue'

type Props = {
    enteredEmail: string,
    agreeWithPolicyAndConditions: boolean,
    showAgreement: boolean,
    isBigBanner? : boolean
}

const props = withDefaults(defineProps<Props>(), {
    isBigBanner: true
})
   
const emailInput = ref<HTMLInputElement | null>(null)

const emit = defineEmits(['submitSignUpForm', 'updateEnteredEmail', 'updateAgreeWithPolicyAndConditions'])

defineExpose({
    emailInput
})

</script>

<template>
    <form class="banner-sign-up-form" @submit.prevent="emit('submitSignUpForm')">
        <div class="row">
            <div class="mx-auto" :class="{'col-md-6': isBigBanner, 'col-11': !isBigBanner}">
                <div class="form-group">
                    <input
                        id="email"
                        ref="emailInput"
                        :value="enteredEmail"
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="What is your best email?"
                        required
                        @input="emit('updateEnteredEmail', $event)"
                    >
                </div>
                <div v-if="showAgreement" class="form-group mb-4 agreement-block">
                    <div class="custom-control custom-checkbox d-flex">
                        <input
                            id="flexSwitchCheckDefault"
                            :checked="agreeWithPolicyAndConditions"
                            type="checkbox"
                            class="custom-control-input"
                            name="tc"
                            required
                            @change="emit('updateAgreeWithPolicyAndConditions', $event)"
                        >
                        <label for="flexSwitchCheckDefault" class="custom-control-label"> 
                            <span class="text-sm mr-6 mr-md-5 cl-text">By creating an account, you agree with our 
                                <a
                                    href="/terms-conditions"
                                    target="_blank"
                                    class="cl-link"
                                >Terms & conditions</a> and <a
                                    href="/privacy"
                                    target="_blank"
                                    class="cl-link"
                                >Privacy policy</a>
                            </span>
                        </label>
                    </div>
                </div>

                <button
                    type="submit"
                    name="submit"
                    class="btn btn-block bg-primary text-white shadow-none"
                >
                    Sign up
                </button>
            </div>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.agreement-block {
    background-color: white ;
    padding: 16px;
    border-radius: 16px;
}

.custom-control-label::before  {
    background-color: #efefef; 
}

.custom-control-input {
    right: 9px;
    top: 50%;
}

.banner-sign-up-form {
    width: 100%;
}
</style>